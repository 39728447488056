import React from "react";
import { Bar } from "react-chartjs-2";

import { Button } from "../../atoms/Button";
import downloadIcon from "../../assets/imgs/icons/downloadIcon_ligthBlue.svg";
import styles from "./BarChart.module.scss";

const options = {
  scales: {
    y: {
      beginAtZero: true,
    },
    x: {
      grid: {
        offset: true,
        display: false,
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  width: 400,
  height: 300,
  plugins: {
    legend: {
      display: false,
    },
  },
};



export const BarChart = ({ data, titleChart }) => {
  return (
    <div className={styles.chartContainer}>
      <div className={styles.titleOptions}>
        {titleChart ? <p className={styles.titleChart}>{titleChart}</p> : null}
        <Button type="button" variant="text" color="primary" className={styles.button} onClick={()=> console.log('descargando')}>
          <img src={downloadIcon} alt="Icono descarga" />
          Descarga .csv
        </Button>
      </div>
      <Bar data={data} options={options} className={styles.barChart} />
    </div>
  );
};
