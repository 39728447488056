import { useContext, useState, useEffect } from "react";

import Spinner from "../../atoms/Spinner";
import { HeaderDashboard } from "../../molecules/HeaderDashboard";
import { DetailDashboard } from "../../molecules/DetailDashboard";
import { apiInterceptor } from "../../services/interceptors/jwt.interceptor";
import { DashboardContext } from "../../context/DashboardContext/DashboardContext";
import styles from "./Dashboard.module.scss";

function Dashboard() {
  const { dataCards, setDataCards, detailCards, setDetailCards } =
    useContext(DashboardContext);
  const [loading, setLoading] = useState(true);
  const getDashboardData = async () => {
    const result = await apiInterceptor({
      method: "GET",
      endpoint: `/tr/dashboard`,
    });

    if (result.statusCode === 201) {
      await updateDataHead(result.data);
      await updateDataBody(result.data);
      setLoading(false);
    }
  };

  const updateDataHead = (data) => {
    setDataCards((prevDataCards) => {
      return prevDataCards.map((card) => {
        const newValue = data[0][card.back_relation];

        return { ...card, total: newValue };
      });
    });
  };

  const updateDataBody = (data) => {
    setDetailCards((prevDataCards) => {
      return prevDataCards.map((card) => {
        const newValue = data[0][card.back_relation];

        return { ...card, total: newValue };
      });
    });
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <section className={styles.dashboard}>
      {loading ? (
        <Spinner className_container="rolesForm" />
      ) : (
        <>
          <HeaderDashboard />
          <DetailDashboard />
        </>
      )}
    </section>
  );
}
export default Dashboard;
