import { apiInterceptor } from "./interceptors/jwt.interceptor";

export const updateEvent = async (id, data) => {
  try {
    return await apiInterceptor({
      method: "PUT",
      endpoint: `/tr2/events/${id}`,
      data,
    });
  } catch (error) {
    console.error(error);
    return error.response;
  }
};
