import { useState } from 'react';

import { ContigoContext } from './ContigoContext';

export const ContigoProvider =({ children }) => {

  const [ userInfo, setUserInfo ] = useState({});
  const [ listSelectUsers, setListSelectUsers ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ isError, setIsError ] = useState(false);
  const [userExists, setUserExists] = useState(false);
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [confirmResponse, setConfirmResponse] = useState({});
  const [isLoadingSend, setIsLoadingSend] = useState(false);
  const [paginationReport, setPaginationReport] = useState({
    page: 1,
    numberOfPages: 1,
  })
  const [actualPageReport, setActualPageReport] = useState(1);
  const [listReport, setListReport] = useState([]);
  const [diligenceInfo, setDiligenceInfo] = useState(null)
  //Filters Report
  const [name, setName] = useState("");
  const [document, setDocument] = useState("");
  const [line, setLine] = useState("");
  const [optReport, setOptReport] = useState(false)
  const [dateInfoReport, setDateInfoReport] = useState(null);
  const [dateInfoUntilReport, setDateInfoUntilReport] = useState(null);
  const [patientGenre, setPatientGenre] = useState(null);
  const [patientLegalAge, setPatientLegalAge] = useState(null);
  const [checkList, setCheckList] = useState([]);
  const [idOptions, setIdOptions] = useState([]);


  //Sort
  const [sort_report, setSort_report] = useState("")
  const [nameSort ,setNameSort] = useState("name:asc")
  const [documentSort ,setDocumentSort] = useState("doc_number:asc")
  const [lineSort ,setLineSort] = useState("service_line:asc")
  const [remarkSort ,setRemarkSort] = useState("remark:asc")
  const [payloadSearch, setPayloadSearch] = useState();

  const [name_patient, setName_patient] = useState("");
  const [document_patient, setDocument_patient] = useState("");
  const [sort_patients, setSort_patients] = useState("");
  const [nameSort_patients, setNameSort_patients] = useState("patient_name:asc");
  const [dateSort_patients, setDateSort_patients] = useState("date:asc");
  const [documentSort_patients, setDocumentSort_patients] = useState("doc_num:asc");
  const [actualPage_pat, setActualPage_pat] = useState(1);
  const [pagination_pat, setCustomePagination_pat] = useState({
    page: 1,
    numberOfPages: 1,
  });
  const [modulePatientsList, setModulePatientsList] = useState([]);
  // const [diligenceInfo, setDiligenceInfo] = useState(null);
  const [dateInfoPatient, setDateInfoPatient] = useState(null);
  const [dateInfoUntilPatient, setDateInfoUntilPatient] = useState(null);
  const [patientGenre_pat, setPatientGenre_pat] = useState(null)
  const [patientLegalAge_pat, setPatientLegalAge_pat] = useState()
  const [patientUpdate, setPatientUpdate] = useState()
  const [patientAlert, setPatientAlert] = useState()
  const [status, setStatus] = useState();


  //Alert
  const [reasonEdit, setReasonEdit] = useState();

  const [selectedScales, setSelectedScales] = useState([]);
  return(
    <ContigoContext.Provider
      value={{
        userInfo, setUserInfo,
        listSelectUsers, setListSelectUsers,
        loading, setLoading,
        isError, setIsError,
        userExists, setUserExists,
        isConfirmation, setIsConfirmation,
        confirmResponse, setConfirmResponse,
        isLoadingSend, setIsLoadingSend,
        paginationReport, setPaginationReport,
        actualPageReport, setActualPageReport,
        listReport, setListReport,
        diligenceInfo, setDiligenceInfo,
        name, setName,
        document, setDocument,
        line, setLine,
        optReport, setOptReport,
        sort_report, setSort_report,
        nameSort ,setNameSort,
        documentSort ,setDocumentSort,
        lineSort ,setLineSort,
        remarkSort ,setRemarkSort,
        payloadSearch, setPayloadSearch,
        dateInfoReport, setDateInfoReport,
        dateInfoUntilReport, setDateInfoUntilReport,
        patientGenre, setPatientGenre,
        patientLegalAge, setPatientLegalAge,
        checkList, setCheckList,
        idOptions, setIdOptions,
        name_patient, setName_patient,
        document_patient, setDocument_patient,
        sort_patients, setSort_patients,
        nameSort_patients, setNameSort_patients,
        dateSort_patients, setDateSort_patients,
        documentSort_patients, setDocumentSort_patients,
        actualPage_pat, setActualPage_pat,
        pagination_pat, setCustomePagination_pat,
        modulePatientsList, setModulePatientsList,
        // diligenceInfo, setDiligenceInfo,
        dateInfoPatient, setDateInfoPatient,
        dateInfoUntilPatient, setDateInfoUntilPatient,
        patientGenre_pat, setPatientGenre_pat,
        patientLegalAge_pat, setPatientLegalAge_pat,
        patientUpdate, setPatientUpdate,
        patientAlert, setPatientAlert,
        status, setStatus,
        reasonEdit, setReasonEdit,
        selectedScales, setSelectedScales
      }}
    >
      {children}
    </ContigoContext.Provider>
  )
}