import { apiInterceptor } from "./interceptors/jwt.interceptor";

export const restorePlanById = async (planId) => {
  try {
    return apiInterceptor({
      method: "PUT",
      endpoint: `/tr2/desenlaces/restore-plan/${planId}`
    });
  } catch (error) {
    console.error(error);
    return error.response;
  }
};
