import { useContext, useEffect, useState } from "react";
import { useField } from "formik";

import CardFSFB from "../../atoms/Card";
import ErrorMessage from "../../atoms/ErrorMessage";
import { ContigoContext } from "../../context/ContigoContext/ContigoContext";
import { TitleAccordion } from "../../molecules/TitleAccordion";

import styles from "./ListPatientSelected.module.scss";

export const ListPatientSelected = ({ ...props }) => {
  const { listSelectUsers, setListSelectUsers } = useContext(ContigoContext);
  const [isActive, setIsActive] = useState(true);
  const [field, meta, helpers] = useField({ ...props });
  const { setValue } = helpers;

  const handleDeleteUser = (id) => {
    setListSelectUsers(listSelectUsers.filter((user) => user.id_patient !== id));
  };

  useEffect(() => {
    setValue(listSelectUsers);
  }, [listSelectUsers]);

  return (
    <CardFSFB>
      <TitleAccordion
        handleClick={() => setIsActive(!isActive)}
        isActive={isActive}
      >
        <h2 className={styles.titleCard}> 1.Pacientes</h2>
      </TitleAccordion>
      {isActive ? (
        <div className={styles.listContainer}>
          {field.value.map((user) => (
            <div className={styles.containerPill}>
              <div key={user.id_patient} className={styles.pillOption}>
                <p className={styles.name}>
                  {user.names} {user.last_name} {user.mother_last_name}{" "}
                </p>
                <p className={styles.document}> {user.doc} </p>
                <button
                  type="button"
                  className={styles.deleteButton}
                  onClick={() => handleDeleteUser(user.id_patient)}
                >
                  X
                </button>
              </div>
            </div>
          ))}
          {meta.touched && <ErrorMessage error={meta.error} meta={meta} />}
        </div>
      ) : null}
    </CardFSFB>
  );
};
