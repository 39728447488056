import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import CardFSFB from "../../atoms/Card";
import UserIcon from "../../assets/imgs/icons/userUpdate.svg";
import { ContigoContext } from "../../context/ContigoContext/ContigoContext";

import styles from "./ConfirmationMonitoring.module.scss";

export const ConfirmationMonitoring = () => {
  const { confirmResponse, setConfirmResponse, setIsConfirmation } = useContext(ContigoContext);
  const navigate = useNavigate();
  const response = confirmResponse?.tracks_object
    ? confirmResponse?.tracks_object
    : [];

  const correctInitialState = [];
  const errorPatients = [];

  for (const patient in response) {
    const planArray = response[patient].planArray;
    if (planArray.length) {
      for (const plan in planArray) {
        const status = planArray[plan].started;
        if (status) {
          correctInitialState.push({
            name: response[patient].patient_name,
            doc_number: response[patient].doc_number,
            message: planArray[plan].message,
          });
        } else {
          errorPatients.push({
            name: response[patient].patient_name,
            doc_number: response[patient].doc_number,
            message: planArray[plan].message,
          });
        }
      }
    } else {
      errorPatients.push({
        name: response[patient].patient_name,
        doc_number: response[patient].doc_number,
        message: "No existen planes para este paciente",
      });
    }
  }

  const responseMap = correctInitialState.map((item) =>{
    return [item.name, item]
  });
  let dataMapArr = new Map(responseMap);
  let correctWithOutDuplicates = [...dataMapArr.values()]

  const handleRedirect = () => {
    setIsConfirmation(false);
    setConfirmResponse({});
    navigate(`/contigo/pacientes`);
  };

  useEffect(() => {
    return () => {
      setIsConfirmation(false);
      setConfirmResponse({});
    }
  },[])

  return (
    <div className={styles.container}>
      <CardFSFB>
        <div className={styles.responseContainer}>
          {correctInitialState.length ? (
            <img
              className={styles.icon}
              src={UserIcon}
              alt="icono de notificación"
            />
          ) : null}

          <div className={styles.confirmationMessageColumn}>
            {correctInitialState.length ? (
              <>
                <h5 className={styles.titleMessage}> ¡Excelente! </h5>
                <div className={styles.sentSuccesfully}>
                  <p>
                    Has iniciado un nuevo monitoreo para{" "}
                    <strong>
                      {correctWithOutDuplicates.length} pacientes:
                    </strong>
                  </p>
                  <p> <strong> Tipo de monitoreo: {confirmResponse.service_line_name} </strong></p>
                  <ul className={styles.listUsers}>
                    {correctWithOutDuplicates.map((patient) => (
                      <li> {patient?.name} - {patient?.doc_number} </li>
                    ))}
                  </ul>
                  <p>
                    Recuerda estar al tanto de su avance en la sección Pacientes
                  </p>
                </div>
              </>
            ) : null}

            {errorPatients.length ? (
              <div className={styles.sendingError}>
                No se pudo iniciar monitoreo para  <strong> {errorPatients.length} pacientes:</strong>
                <ul className={styles.listUsers}>
                  {errorPatients.map((patient) => (
                    <li>
                     {patient?.name} - {patient?.doc_number} : {patient?.message}
                    </li>
                  ))}
                </ul>
                <p>Inténtalo de nuevo</p>
              </div>
            ) : null}
          </div>
        </div>
      </CardFSFB>
      <button
        type="button"
        onClick={handleRedirect}
        className={styles.buttonRedirect}
      >
        Aceptar
      </button>
    </div>
  );
};
