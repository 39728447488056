import axios from "axios";
import { apiInterceptor } from "../interceptors/jwt.interceptor";

const API_URL = process.env.REACT_APP_API_SERVER;

export const searchPatient = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/patients/info`,data,{
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
        'Content-Type': 'application/json'
      }
    });
    return response.data.data;

  } catch (error) {
    console.error(error);
    return error;
  }
};


export const getPatientDetails = async(id) => {
  try {
    const response = await axios.get(`${API_URL}/recovery/track/patients/detail/${id}`,{
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
        'Content-Type': 'application/json'
      }
    });
    return response.data.data;

  } catch (error) {
    console.error(error);
    return error;
  }
}

export const createNewMonitoring = async (data) => {
  const response = await apiInterceptor({
    method: 'POST',
    endpoint: '/tr/contigo/start',
    data: data
   })
   return response;

}



export const getAlertByPatientId = async(id) => {
  try {
    const response = await axios.get(`${API_URL}/recovery/track/alerts/${id}`,{
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
        'Content-Type': 'application/json'
      }
    });

    return response;

  } catch (error) {
    console.error(error);
    return error;
  }
}

export const startTracking = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/recovery/track/trace`,data,{
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
        'Content-Type': 'application/json'
      }
    });

    return response;

  } catch (error) {
    console.error(error);
    return error;
  }
}

export const updateAlert = async (data) => {
  try {
    const response = await axios.put(`${API_URL}/recovery/track/trace`,data,{
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
        'Content-Type': 'application/json'
      }
    });

    return response;

  } catch (error) {
    console.error(error);
    return error;
  }
}

export const deleteAlert = async (idQuestion,email, remarkId) => {
  try {
    const response = await axios.delete(`${API_URL}/recovery/track/trace/${idQuestion}?email=${email}&remarkId=${remarkId}`,{
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
        'Content-Type': 'application/json'
      }
    });

    return response;

  } catch (error) {
    console.error(error);
    return error;
  }
}