import React from 'react';

import styles from './infoCard.module.scss';

const InfoCard = ({variant, color, icon, number, detail, onClick }) => {
  return (
    <div className={`${styles.cardContainer} ${styles[variant]}`} onClick={onClick}>
      <div className={`${variant === 'medium'? styles.iconContainer : styles.smallIcon} ${styles[color]}`}>
        <img src={icon} alt="icono dashboard" />
      </div>
      <div className={`${variant === 'medium'? styles.infoContainer : styles.smallInfo}`}>
        <p className={` ${variant === 'medium' ? styles.numberDetail :  styles.bigNumber}`}> {number}</p>
         <p className={` ${variant === 'medium' ? styles.detailText : styles.bigDetailText}`}>{detail}</p>
      </div>
    </div>
  )
}

export default InfoCard;