import React, { useContext, useEffect } from "react";

import { ContigoContext } from "../../context/ContigoContext/ContigoContext";

import styles from "./ListScales.module.scss";

export const ListScales = () => {
  const { setSelectedScales, selectedScales } = useContext(ContigoContext);

  const removeItem = (id) => {
    const newValues = selectedScales.filter((item) => item.id !== id);
    setSelectedScales(newValues);
  };

  useEffect(() => {
    return () => setSelectedScales([]);
  }, []);

  return (
    <div className={styles.listForms}>
      <h5 className={styles.titleForm}>Formularios</h5>
      <div className={styles.optionsContainer}>
        {selectedScales?.map((item, i) => (
          <div className={styles.listOption}>
            {item.name}
            <button
              type="button"
              onClick={() => removeItem(item.id)}
              className={styles.deleteValue}
            >
              X
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
