import { useContext, useState, useEffect } from "react";

import Spinner from "../../atoms/Spinner";
import AddButton from "../../atoms/AddButton";
import { ContigoContext } from "../../context/ContigoContext/ContigoContext";

import ErrorIcon from "../../assets/imgs/icons/error_x.svg";
import styles from "./PatientAddInfo.module.scss";

export const PatientAddInfo = () => {
  const {
    userInfo,
    setUserInfo,
    setListSelectUsers,
    loading,
    listSelectUsers,
    isError,
    userExists,
    setUserExists,
  } = useContext(ContigoContext);
  const [age, setAge] = useState(null);

  const calculateAge = (date) => {
    const todaydate = new Date();
    const birthdate = new Date(date);

    let edad = todaydate.getFullYear() - birthdate.getFullYear();
    return edad;
  };

  useEffect(() => {
    let edad = calculateAge(userInfo?.birtdate);
    setAge(edad);
  }, [userInfo]);

  const handleAdd = (user) => {
    const newUser = listSelectUsers.filter(
      (u) => u.id_patient === user.id_patient
    );

    if (!newUser.length) {
      setListSelectUsers((prev) => [...prev, user]);
      setUserInfo({});
    } else {
      setUserExists(true);
    }
  };

  return (
    <>
      {loading && <Spinner className_container="alertDel" />}
      {Object.keys(userInfo).length > 0 ? (
        <div className={styles.container}>
          <p className={styles.name}>
            {userInfo.names} {userInfo.last_name} {userInfo.mother_last_name}
          </p>
          <div className={styles.userInfoContainer}>
            <div className={styles.detail}>
              <p className={styles.title}>Tipo de documento</p>
              <p> {userInfo.tdoc.detail} </p>
            </div>
            <div className={styles.detail}>
              <p className={styles.title}>Documento</p>
              <p> {userInfo.doc} </p>
            </div>
            <div className={styles.detail}>
              <p className={styles.title}>Edad</p>
              <p> {age} años</p>
            </div>
            <div className={styles.detail}>
              <p className={styles.title}>Genero</p>
              <p> {userInfo.gender === "F" ? "Femenino" : "Masculino"} </p>
            </div>
            <div className={styles.detail}>
              <p className={styles.title}>Dirección</p>
              <p> {userInfo.address} </p>
            </div>
            <div className={styles.detail}>
              <p className={styles.title}>Correo Electrónico</p>
              <p> {userInfo.email} </p>
            </div>
            <div className={styles.detail}>
              <p className={styles.title}>Celular</p>
              <p>{userInfo.mobile} </p>
            </div>
            <div className={styles.detail}>
              <p className={styles.title}>Fijo</p>
              <p> {userInfo.phone} </p>
            </div>
          </div>
          <div
            className={`${styles.buttonContainer} ${
              userExists && styles.errorUser
            }`}
          >
            {userExists ? (
              <div className={styles.errorMessageContainer}>
                <img src={ErrorIcon} alt="Icono de error" />
                <p className={styles.errorMessage}>
                  El paciente ya fue agregado previamente
                </p>
              </div>
            ) : null}
            <AddButton onClick={() => handleAdd(userInfo)} type="button" />
          </div>
        </div>
      ) : isError ? (
        <div className={styles.errorMessageContainer}>
          <img src={ErrorIcon} alt="Icono de error" />
          <p className={styles.errorMessage}>
            No se encontró paciente asignado al número de documento
          </p>
        </div>
      ) : null}
    </>
  );
};
