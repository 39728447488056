import React, { useContext } from "react";

import InfoCard from "../../atoms/InfoCard";
import { AppContext } from "../../context/AppContext/AppContext";
import { DashboardContext } from "../../context/DashboardContext/DashboardContext";

import styles from "./HeaderDashboard.module.scss";

export const HeaderDashboard = () => {
  const { user } = useContext(AppContext);
  const { dataCards } = useContext(DashboardContext);

  return (
    <section className={styles.container}>
      <div>
        <h1 className={styles.headerTitle}>Hola, {user.name}</h1>
        <p className={styles.headerText}>
          Aquí encontrarás un resumen de la gestión
        </p>
      </div>
      <div className={styles.principalCards}>
        {dataCards.map((card, index) => (
          <InfoCard
            key = {`${card.detail}-${index}`}
            variant="small"
            number={card.total}
            detail={card.detail}
            icon={card.icon}
          />
        ))}
      </div>
    </section>
  );
};
