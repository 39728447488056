import React, { useContext } from "react";
import { ContigoContext } from "../../context/ContigoContext/ContigoContext";

import CardFSFB from "../../atoms/Card";

import { SearchPatientForm } from "../../molecules/SearchPatientForm";
import { PatientAddInfo } from "../../molecules/PatientAddInfo";
import UpdateData from "../../molecules/UpdateData";
import { searchPatient } from "../../services/Contigo/patient";

import styles from "./SearchPatient.module.scss";

export const SearchPatient = () => {
  const { userInfo, payloadSearch, setUserInfo, setIsError } =
    useContext(ContigoContext);
  let names =
    userInfo?.names +
    " " +
    userInfo?.last_name +
    " " +
    userInfo?.mother_last_name;

  const updateData = async () => {
    const response = await searchPatient(payloadSearch);
    if (response.response.length > 0) {
      setUserInfo(response.response[0]);
    } else {
      setIsError(true);
    }
  };

  return (
    <CardFSFB>
      <div className={styles.headerContainer}>
        <h2 className={styles.titleCard}>Buscar paciente</h2>
        <div
          className={`
            ${styles.updateData} 
            ${
              userInfo?.id_patient?.toString().length >= 1 && styles.displayNone
            }
          `}
        >
          <UpdateData
            name={names}
            email={userInfo?.email}
            id={userInfo?.id_patient}
            mobile={userInfo?.mobile}
            phone={userInfo?.phone}
            updateInfo={updateData}
          />
        </div>
      </div>
      <div className={styles.patientContainer}>
        <SearchPatientForm />
        <PatientAddInfo />
      </div>
    </CardFSFB>
  );
};
