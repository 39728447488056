import { useState, useMemo } from "react";
import { useTable, useFilters, useSortBy } from "react-table";
import * as moment from "moment-timezone";

import CardFSFB from "../../atoms/Card";
import TextInputTable from "../../atoms/TextInputTable";
import { TitleAccordion } from "../../molecules/TitleAccordion";
import { Comment } from "../../molecules/Comment";
import deleteWhite from "../../assets/imgs/icons/deleteWhite.svg";
import arrowBack from "../../assets/imgs/icons/arrowBack.svg";
import stopTrackIcon from "../../assets/imgs/icons/stopTrackIcon.svg";
import sentTrackIcon from "../../assets/imgs/icons/resend.svg";
import ArrowDown from "../../assets/imgs/icons/arrowDownSort.svg";
import ArrowUp from "../../assets/imgs/icons/arrowUpSort.svg";
import styles from "./TableCardFSFB.module.scss";
import PlansEliminated from "../PlansEliminated";

function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <TextInputTable
      callback={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={""}
      value={filterValue || ""}
    />
  );
}

export default function TableCardFSFB({
  deletedColumns = [],
  deletedData = [],
  columns,
  data,
  trackInfo,
  diligenceInfo,
  onClick,
  equalNumber,
  location,
  accordionData,
  undelete,
  handleSend,
}) {
  const [isActive, setIsActive] = useState(
    equalNumber === trackInfo?.track_number ||
      equalNumber === accordionData?.event_number
      ? true
      : false
  );

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        // data,
        data: data || [],
        defaultColumn,
        // filterTypes,
      },
      useFilters,
      useSortBy
    );

  const isDeceasedAndInProcess =
    accordionData?.status.code === 1 && Boolean(diligenceInfo?.deceased_date);

  const variant =
    isDeceasedAndInProcess ? "deceased" :
      trackInfo?.color === 1 || accordionData?.status.code === 1
        ? "process"
        : trackInfo?.color === 2 || accordionData?.status.code === 2
          ? "finished"
          : trackInfo?.color === 5 || accordionData?.status.code === 5
            ? "deceased"
            : "paused";

  function convertDateFormat(string) {
    let info = string.slice(0, 10).split("-").reverse().join("-");
    return info;
  }

  return (
    <>
      <CardFSFB className={styles.card} variant={variant}>
        <TitleAccordion
          handleClick={() => setIsActive(!isActive)}
          isActive={isActive}
          isPatientDetail={diligenceInfo?.tracks || accordionData}
        >
          {
            // start card for Desenlaces
            location === "desenlaces" ? (
              accordionData ? (
                accordionData?.status.code === 1 ||
                  accordionData?.status.code === 2 ? (
                  <section className={styles.trackInProcessDesenlaces}>
                    <div className={styles.headerDontDeleted}>
                      <div>
                        Evento: <span>{accordionData?.event_number}</span>
                      </div>
                      <div>
                        Ingreso:{" "}
                        <span>
                          {convertDateFormat(accordionData?.ingress_date)}{" "}
                        </span>
                      </div>
                      {accordionData?.egress_date ? (
                        <div>
                          Egreso:{" "}
                          <span>
                            {convertDateFormat(accordionData?.egress_date)}{" "}
                          </span>
                        </div>
                      ) : null}
                      <div>
                        Línea: <span>{accordionData?.service_line}</span>
                      </div>
                    </div>
                    {!isDeceasedAndInProcess && (
                      <div
                        className={styles.stopTrackIcon}
                        onClick={() => onClick(accordionData?.id)}
                      >
                        <img src={deleteWhite} alt="Detener evento"></img>
                      </div>
                    )}
                  </section>
                ) : (
                  <section className={styles.trackPausedDesenlaces}>
                    <div className={styles.columnsContainer}>
                      <div className={styles.firstColumnDesenlaces}>
                        <div>
                          Evento: <span>{accordionData?.event_number}</span>
                        </div>
                        <div>
                          Ingreso:{" "}
                          <span>
                            {convertDateFormat(accordionData?.ingress_date)}{" "}
                          </span>
                        </div>
                        {accordionData?.egress_date ? (
                          <div>
                            Egreso:{" "}
                            <span>
                              {convertDateFormat(accordionData?.egress_date)}{" "}
                            </span>
                          </div>
                        ) : null}
                        <div>
                          Línea: <span>{accordionData?.service_line}</span>
                        </div>
                      </div>
                      {accordionData?.status?.history ? (
                        <div className={styles.lastColumnDesenlaces}>
                          <div>
                            Eliminado por:
                            {accordionData?.status?.history?.map((history) => (
                              <span>
                                {" "}
                                {history?.user},{" "}
                                {moment
                                  .tz(history?.date, "America/Bogota")
                                  .format("YYYY-MM-DD HH:mm:ss")}
                                , {history?.remark?.detail}
                              </span>
                            ))}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    {!isDeceasedAndInProcess && (
                      <div
                        className={styles.stopTrackIcon}
                        onClick={() => undelete(accordionData?.id)}
                      >
                        <img src={arrowBack} alt="Activar evento"></img>
                      </div>
                    )}
                  </section>


                )
              ) : // end card for Desenlaces
                null
            ) : // start card for Contigo
              location === "contigo" ? (
                diligenceInfo?.tracks ? (
                  trackInfo?.color === 1 ? (
                    <section className={styles.trackInProcess}>
                      <div className={styles.numberSize}>
                        {trackInfo?.track_number}.
                      </div>
                      <div className={styles.headerInProcess}>
                        <div>
                          Inicio:{" "}
                          <span>
                            {moment
                              .tz(trackInfo?.start_date, "America/Bogota")
                              .format("DD-MM-YYYY HH:mm")}
                          </span>
                        </div>
                        <div>
                          Tipo: <span>{trackInfo?.service_line_name}</span>
                        </div>
                        <div>
                          <span>Monitoreo {trackInfo?.status}</span>
                        </div>
                      </div>
                      <div
                        className={styles.resendTracing}
                        onClick={() => handleSend(trackInfo?.track_id)}
                      >
                        <img src={sentTrackIcon} alt="Reenviar monitoreo"></img>
                      </div>
                      <div
                        className={styles.stopTrackIcon}
                        onClick={() => onClick(trackInfo?.track_id)}
                      >
                        <img src={stopTrackIcon} alt="Detener monitoreo"></img>
                      </div>
                    </section>
                  ) : trackInfo?.color === 2 ? (
                    <section className={styles.trackFinished}>
                      <div className={styles.numberSize}>
                        {trackInfo?.track_number}.
                      </div>
                      <div className={styles.headerFinished}>
                        <div>
                          Inicio:{" "}
                          <span>
                            {moment
                              .tz(trackInfo?.start_date, "America/Bogota")
                              .format("DD-MM-YYYY HH:mm")}
                          </span>
                        </div>
                        <div>
                          Tipo: <span>{trackInfo?.service_line_name}</span>
                        </div>
                        <div>
                          <span>Monitoreo {trackInfo?.status}</span>
                        </div>
                      </div>
                    </section>
                  ) : (
                    <section className={styles.trackPaused}>
                      <div className={styles.numberSize}>
                        {trackInfo?.track_number}.
                      </div>
                      <div className={styles.columnsContainer}>
                        <div className={styles.firstColumn}>
                          <div>
                            Inicio:{" "}
                            <span>
                              {moment
                                .tz(trackInfo?.start_date, "America/Bogota")
                                .format("DD-MM-YYYY HH:mm")}
                            </span>
                          </div>
                          <div>
                            Tipo: <span>{trackInfo?.service_line_name}</span>
                          </div>
                          <div>
                            <span>Monitoreo {trackInfo?.status}</span>
                          </div>
                        </div>
                        <div className={styles.lastColumn}>
                          <div>
                            Detenido por: <span>{trackInfo?.user_name}</span>
                          </div>
                          <div>
                            Motivo: <span>{trackInfo?.remark_value}</span>
                          </div>
                        </div>
                      </div>
                    </section>
                  )
                ) : null
              ) : null
            // end card for Contigo
          }
        </TitleAccordion>
        {isActive ? (
          <>
            <div className={`${styles.containerTable} ${!!deletedData.length ? styles.containerTableNoBorder : ""}`}>
              <table className={styles.table} {...getTableProps()}>
                <thead className={styles.thead}>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps()}
                          style={{ width: `${column.width}%` }}
                          className={
                            column.scaleNameWidth === true
                              ? styles.scaleNameWidth
                              : column.generalWidth === true
                                ? styles.generalWidth
                                : styles.header
                          }
                        >
                          <div className={styles.headerName}>
                            <p className={styles.headerLabel}>
                              <div
                                {...(accordionData
                                  ? column.getSortByToggleProps()
                                  : null)}
                                className={styles.headerContainer}
                              >
                                {column.render("Header")}
                                {accordionData ? (
                                  <div className={styles.arrow}>
                                    {column.sort ? (
                                      column.isSorted ? (
                                        column.isSortedDesc ? (
                                          <img
                                            src={ArrowDown}
                                            className={styles.arrowSort}
                                            alt="Flecha abajo"
                                          ></img>
                                        ) : (
                                          <img
                                            src={ArrowUp}
                                            className={styles.arrowSort}
                                            alt="Flecha abajo"
                                          ></img>
                                        )
                                      ) : (
                                        ""
                                      )
                                    ) : null}
                                  </div>
                                ) : null}
                              </div>
                              <div>
                                {column.canFilter
                                  ? column.render("Filter")
                                  : null}
                              </div>
                            </p>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} className={styles.tbody}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        className={styles.row}
                        style={{ width: `${row.width}%` }}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              style={{ textAlign: cell.column.textAlign }}
                              className={styles.cells}
                            >
                              <div className={styles.overflow}>
                                {cell.render("Cell")}
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {!!deletedData.length && <PlansEliminated columns={deletedColumns} data={deletedData} />}
          </>
        ) : null}
      </CardFSFB>

      {location === "contigo" && isActive ? (
        <Comment trackInfo={trackInfo} />
      ) : null}
    </>
  );
}
