import { useContext, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import * as moment from "moment-timezone";

import Modal from "../../atoms/Modal";
import { Button } from "../../atoms/Button";
import TableCardFSFB from "../../molecules/TableCardFSFB";
import DeleteTracks from "../../molecules/DeleteTracks";
import { ResendTrack } from "../../molecules/ResendTrack";
import { DesenlacesContext } from "../../context/DesenlacesContext/DesenlacesContext";
import { ManagerContext } from "../../context/ManagerContext/ManagerContext";
import { ContigoContext } from "../../context/ContigoContext/ContigoContext";
import { AppContext } from "../../context/AppContext/AppContext";
import greenAlert from "../../assets/imgs/icons/greenAlert.svg";
import yellowAlert from "../../assets/imgs/icons/yellowAlert.svg";
import statusChecked from "../../assets/imgs/icons/statusChecked.svg";
import statusUnchecked from "../../assets/imgs/icons/statusUnchecked.svg";
import { setItem } from "../../services/localStorage";
import styles from "./PatientDetailTable.module.scss";

export const PatientDetailTable = () => {
  const navigate = useNavigate();
  const { diligenceInfo } = useContext(DesenlacesContext);
  const { setLocation } = useContext(ManagerContext);
  const { status } = useContext(ContigoContext);
  const { user, setModulesSelect } = useContext(AppContext);
  const [openDeleteTracks, setOpenDeleteTracks] = useState(false);
  const [openSendTracks, setOpenSendTracks] = useState(false);
  const [trackDelete, setTrackDeleteTracks] = useState();
  const [trackSend, setTrackSend] = useState(null);

  const handleRedirect = (idPlan) => {
    setModulesSelect("contigo");
    setItem("module", "contigo");
    setLocation("contigoDetail");
    navigate(
      `/contigo/pacientes/detalle/diligenciamiento/${diligenceInfo.id_patient}/${idPlan}`
    );
  };

  const handleRedirectAlert = (idPlan) => {
    navigate(
      `/contigo/pacientes/detalle/alerta/${diligenceInfo.id_patient}/${idPlan}`
    );
  };

  const handleStop = (trackId) => {
    setTrackDeleteTracks([trackId]);
    setOpenDeleteTracks(true);
  };

  const handleCloseDeleteTracks = () => {
    setOpenDeleteTracks(false);
    status === 200 && navigate(`/contigo/pacientes`);
  };
  const lastNumArray = diligenceInfo.tracks.map((number) => {
    return number.track_number;
  });

  const COLUMNS = [
    {
      Header: "Fecha",
      accessor: "date",
      Cell: ({ row }) => (
        <p className={styles.rowContainer}>
          {" "}
          {moment.tz(row.values.date, "America/Bogota").format("YYYY-MM-DD")}
        </p>
      ),
      width: "15",
    },
    {
      Header: "Formulario",
      accessor: "scale_name",
      width: "56",
      Cell: ({ row }) => {
        return (
          <p
            className={`${styles.rowContainer} ${styles.namePlan}`}
            onClick={() => handleRedirect(row.original.id_plan)}
          >
            {row.original.scale_name}
          </p>
        );
      },
    },
    {
      Header: "Período",
      accessor: "frequency",
      width: "8",
      textAlign: "center",
    },
    {
      Header: "Finalizado",
      accessor: "is_finished",
      Cell: ({ row }) => {
        return (
          <img
            src={
              row.original.is_finished === true
                ? statusChecked
                : statusUnchecked
            }
            alt="check"
          />
        );
      },
      disableFilters: true,
      width: "8",
      textAlign: "center",
    },
    {
      Header: "Alerta",
      accessor: "alert",
      Cell: ({ row }) => {
        return (
          <>
            {row.original.alert === true ? (
              <>
                {row.original.traced ? (
                  <img
                    src={greenAlert}
                    alt="check"
                    className={styles.alertIcon}
                  />
                ) : (
                  <img
                    src={yellowAlert}
                    alt="check"
                    className={styles.alertIcon}
                  />
                )}
              </>
            ) : null}
          </>
        );
      },

      disableFilters: true,
      width: "5",
      textAlign: "center",
    },
    {
      Header: "Acción",
      accessor: "traced",
      Cell: ({ row }) => {
        return (
          <>
            {row.original.is_finished && row.original.alert ? (
              <Button
                type="button"
                variant="text"
                color="grey"
                onClick={() => handleRedirectAlert(row.original.id_plan)}
              >
                {row.original.traced ? "Ver detalles" : "Gestionar"}
              </Button>
            ) : null}
          </>
        );
      },
      disableFilters: true,
      width: "8",
      textAlign: "center",
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  const data = diligenceInfo?.tracks;
  const equalNumber = lastNumArray[0];

  const openSendNotification = (trackId) => {
    setTrackSend(trackId);
    setOpenSendTracks(true);
  };
  return (
    <>
      <section className={styles.tablesContainer}>
        {diligenceInfo.tracks.map((trackInfo, id) => (
          <div key={id}>
            <TableCardFSFB
              location="contigo"
              columns={columns}
              data={data[id].track_list}
              trackInfo={trackInfo}
              diligenceInfo={diligenceInfo}
              onClick={handleStop}
              equalNumber={equalNumber}
              handleSend={openSendNotification}
            />
           
          </div>
        ))}
      </section>
      <Modal
        isOpen={openDeleteTracks}
        onClose={handleCloseDeleteTracks}
        variant="large"
      >
        <DeleteTracks
          onClose={handleCloseDeleteTracks}
          tracksDelete={diligenceInfo.length}
          selectOptions={diligenceInfo}
          user={user}
          trackDelete={trackDelete}
        />
      </Modal>

      <Modal
        isOpen={openSendTracks}
        onClose={() => setOpenSendTracks(false)}
        variant="large"
      >
        <ResendTrack
          onClose={() => setOpenSendTracks(false)}
          trackId={trackSend}
        />
      </Modal>
    </>
  );
};
