import { useContext } from "react";

import { UsersContext } from "../../context/UsersContext/UsersContext";
import UserIcon from "../../assets/imgs/icons/userUpdate.svg";
import DeleteIcon from "../../assets/imgs/icons/DeleteIcon.svg";
import styles from "./UserUpdateConfirmation.module.scss";

const UserUpdateConfirmation = ({ status }) => {
  const { editStatus, createStatus } = useContext(UsersContext);
  return (
    <div className={styles.updateConfirmationContainer}>
      {status === 200 || editStatus === 200 || createStatus === 201 ? (
        <>
          <img src={UserIcon} alt="icono de confirmación actualización" />
          <h2 className={styles.updateTitle}>Datos actualizados</h2>
        </>
      ) : (
        <>
          <img src={DeleteIcon} alt="icono de error actualización" />
          <h2 className={styles.updateTitle}>
            No se pudo completar la acción, <br />
            inténtalo de nuevo
          </h2>
        </>
      )}
    </div>
  );
};

export default UserUpdateConfirmation;
