import { useState, useContext } from "react";

import InfoCard from "../../atoms/InfoCard";
import { BarChart } from "../../molecules/BarChart/BarChart";
import { DashboardContext } from "../../context/DashboardContext/DashboardContext";
import { mockData } from "./mock";
import styles from "./DetailDashboard.module.scss";

export const DetailDashboard = () => {
  const { detailCards } = useContext(DashboardContext);
  const [data, setData] = useState({});
  const [titleChart, setTitleChart] = useState("");

  const getData = (type) => {
    setData(mockData);
    setTitleChart(type);
  };



  
  return (
    <section>
      <p className={styles.desenlacesTitle}>Desenlaces</p>
      <section className={styles.listCards}>
        {detailCards.map((card, index) => (
          <InfoCard
            variant="medium"
            color={card.color}
            number={card.total}
            detail={card.detail}
            icon={card.icon}
            onClick={() => getData(card.detail)}
          />
        ))}
      </section>
      {Object.keys(data).length ? (
        <>
          <BarChart data={data} titleChart={titleChart} />
        </>
      ) : null}
    </section>
  );
};
