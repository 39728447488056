import { useState } from "react";

import { DashboardContext } from "./DashboardContext";

import totaLinesIcon from "../../assets/imgs/icons/total_lines.svg";
import patientsIcon from "../../assets/imgs/icons/patients.svg";
import totalPatientsIcon from '../../assets/imgs/icons/total_patients.svg';
import completedResultsIcon from '../../assets/imgs/icons/completed_results.svg';
import plannedOutcomesIcon from '../../assets/imgs/icons/planned_outcomes.svg';
import deceasedPatientsIcon from '../../assets/imgs/icons/deceased_patients.svg';
import patientsMeasuredIcon from '../../assets/imgs/icons/patients_measured.svg';
import pendingPatientsIcon from '../../assets/imgs/icons/pending_patients.svg';
import pendingScalesIcon from '../../assets/imgs/icons/pending_scales.svg';

export const DashboardProvider = ({ children }) => {
  const [dataCards, setDataCards] = useState([
    {
      detail: "Lineas activas",
      icon: totaLinesIcon,
      total:0,
      back_relation: 'head_active_lines'
    },

    {
      detail: "Pacientes",
      icon: patientsIcon,
      total:0,
      back_relation: 'head_total_patients'
    },

  ]);

  const [detailCards, setDetailCards] = useState([
    {
      detail: "Total pacientes",
      icon: totalPatientsIcon,
      color: 'primary',
      total:0,
      back_relation: 'body_total_patients'

    },
    {
      detail: "Desenlaces diligenciados",
      icon: completedResultsIcon,
      color: 'primary',
      total:0,
      back_relation: 'body_desenlaces_completed'
    },
    {
      detail: "Desenlaces planeados",
      icon: plannedOutcomesIcon,
      total:0,
      color: 'primary',
      back_relation: 'body_desenlaces_planing'
    },
    {
      detail: "Pacientes fallecidos",
      icon: deceasedPatientsIcon,
      color: 'alert',
      total:0,
      back_relation: 'body_deceased_patients'
    },
    {
      detail: "Pacientes medidos",
      icon: patientsMeasuredIcon,
      color: 'primary',
      total:0,
      back_relation: 'body_uptodate_patients'
    },
    {
      detail: "Pacientes pendientes",
      icon: pendingPatientsIcon,
      color: 'alert',
      total:0,
      back_relation: 'body_nottodate_patients'
    },
    {
      detail: "Desenlaces pendientes por medir",
      icon: pendingScalesIcon,
      color: 'alert',
      total:0,
      back_relation: 'body_nottodate_plans'
    },
  ]);

  return (
    <DashboardContext.Provider
      value={{ 
        detailCards, setDetailCards, 
        dataCards, setDataCards
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
