import { useContext, useState, useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as moment from "moment-timezone";

import { DesenlacesContext } from "../../context/DesenlacesContext/DesenlacesContext";
import { ManagerContext } from "../../context/ManagerContext/ManagerContext";
import { AppContext } from "../../context/AppContext/AppContext";

import { getOne, undeleteTracing } from "../../services/patientsDesenlaces";
import { setItem } from "../../services/localStorage";

import withAuth from '../../HOC/withAuth';

import { RestorePlanStepper } from "../../molecules/RestorePlanStepper";
import TableCardFSFB from "../../molecules/TableCardFSFB";
import DeleteEvents from "../../molecules/DeleteEvents";
import { EditEvents } from "../../molecules/EditEvents";

import PatientDataTable from "../../atoms/PatientDataTable";
import ModalContent from "../../atoms/ModalContent";
import { Tooltip } from "../../atoms/Tooltip";
import Spinner from "../../atoms/Spinner";
import Modal from "../../atoms/Modal";

import statusUnchecked from "../../assets/imgs/icons/statusUnchecked.svg";
import statusChecked from "../../assets/imgs/icons/statusChecked.svg";
import arrowGreen from "../../assets/imgs/icons/arrowGreenColored.svg";
import arrowGrey from "../../assets/imgs/icons/arrowGreyColored.svg";
import delete_rol from "../../assets/imgs/icons/delete_rol.svg";
import userUpdate from "../../assets/imgs/icons/userUpdate.svg";
import cure from "../../assets/imgs/icons/cure.svg";

import styles from "./PatientDesenlacesDetail.module.scss";

function PatientDesenlacesDetail() {
  const [openDeleteTracing, setOpenDeleteTracing] = useState(false);
  const [openUndeleteTracing, setOpenUndeleteTracing] = useState(false);
  const [openEditTracing, setOpenEditTracing] = useState(false);
  const [undeleteStatus, setUndeleteStatus] = useState();
  const [tracingDelete, setTracingDelete] = useState();
  const [tracingUndelete, setTracingUndelete] = useState();
  const [loading, setLoading] = useState(false);
  const [equalNumber, setEqualNumber] = useState();

  const [openRestorePlan, setOpenRestorePlan] = useState(false);
  const [planId, setPlanId] = useState("");

  const {
    setDiligenceInfo,
    setPatientsInfo,
    patientsInfo,
    idScaleSelected,
    setIdScaleSelected,
  } = useContext(DesenlacesContext);

  const { setLocation } = useContext(ManagerContext);

  const {
    user,
    modules,
    modulesMixer,
    setIdPatient,
    idPatient,
    setModulesSelect,
  } = useContext(AppContext);

  const navigate = useNavigate();
  const params = useParams();

  const idEvent = [{ id: tracingDelete }];

  const handleStop = (tracingId) => {
    setTracingDelete(tracingId);
    setOpenDeleteTracing(true);
  };

  const handleUndelete = (tracingId) => {
    setTracingUndelete(tracingId);
    setOpenUndeleteTracing(true);
  };

  const handleUndeleteClose = () => {
    setOpenUndeleteTracing(false);
    setUndeleteStatus();
    navigate(`/desenlaces/pacientes`);
  };

  const request = async () => {
    try {
      const data = await getOne(idPatient);
      if (data?.response?.[0]) {
        setPatientsInfo(data?.response?.[0]);
        setDiligenceInfo(data?.response?.[0]);
      } else if (data.status === 400) {
        navigate('/error/404')
      }
    } catch (error) {
      console.error("Error in request");
    }
  };

  const undelete = async () => {
    setLoading(true);
    try {
      const body = {
        tracingId: tracingUndelete,
      };
      const data = await undeleteTracing(body);
      if (data?.status === 200) {
        setLoading(false);
        setUndeleteStatus(data?.status);
      }
    } catch (error) {
      console.error("Error in request");
    }
  };

  const handleDiligence = async (info) => {
    const id_plan = info.id_plan;
    setModulesSelect("desenlaces");
    setItem("module", "desenlaces")
    setLocation("patientDetail");
    navigate(
      `/desenlaces/pacientes/diligenciamiento/${params.idPatient}/${id_plan}`
    );
  };

  const handleEditedScale = async (data) => {
    setIdScaleSelected(data.id_plan);
    setItem("module", "desenlaces");
    // await setEditedFinishScale(true);
    setOpenEditTracing(true);
  };

  const handleCloseModal = async () => {
    setOpenEditTracing(false);
  }

  const COLUMNS = [
    {
      Header: "Fecha",
      accessor: "date",
      Cell: ({ row }) => (
        <p>
          {moment.tz(row.values.date, "America/Bogota").format("YYYY-MM-DD")}
        </p>
      ),
      width: 15,
      sort: true,
    },
    {
      Header: "Desenlace",
      accessor: "scale_name",
      Cell: ({ row }) => (
        <p
          className={`${styles.rowContainer} ${!row.original.active && styles.disableOption}`}
          onClick={() => {
            row.original.active && handleDiligence(row.original)
          }}
        >
          {row.values.scale_name}
        </p>
      ),
      width: 52,
      sort: true,
    },
    {
      Header: "Período",
      accessor: "frequency",
      width: 9,
      sort: true,
    },
    {
      Header: "Responsable",
      accessor: "responsible",
      width: 9,
      sort: true,
    },
    {
      Header: "Finalizado",
      accessor: "is_finished",
      id: "is_finished",
      Cell: ({ row }) => {
        return (
          <img
            src={
              row.original.is_finished === true
                ? statusChecked
                : statusUnchecked
            }
            alt="check"
          />
        );
      },
      disableFilters: true,
      width: 7.5,
      textAlign: "center",
    },
    {
      Header: "",
      accessor: "is_finished",
      id: "edit",
      Cell: ({ row }) => {
        return (
          <div className={styles.editContainer}>
            {row.original.is_finished ? (
              <p
                className={styles.editText}
                onClick={() => handleEditedScale(row.original)}
              >
                Editar
              </p>
            ) : null}
            {row.original.edited.status ? (
              <Tooltip
                text={`Última edición\n${moment
                  .tz(row.original.edited.last_edit, "America/Bogota")
                  .format("YYYY MM DD - HH:mm:ss")}`}
                variant="dark"
                img={cure}
                customStyle={styles.tooltipStyle}
              />
            ) : null}
            {row.original.restored_date && (
              <div className={styles.restoredTooltip}>
                <Tooltip
                  text={`Restaurado\n${moment
                    .tz(row.original.restored_date, "America/Bogota")
                    .format("YYYY MM DD - HH:mm:ss")}`}
                  variant="dark"
                  img={arrowGrey}
                  customStyle={styles.tooltipStyle} />
              </div>
            )}
          </div>
        );
      },
      disableFilters: true,
      width: 7.5,
    },
  ];

  const DELETED_PLANS_COLUMNS = [
    {
      Header: "Planes borrados",
      accessor: "scale_name",
      Cell: ({ row }) => (
        <p className={styles.deletedPlan}>
          {row.values.scale_name}
        </p>
      ),
      width: 67,
      sort: true,
    },
    {
      Header: "",
      accessor: "date",
      Cell: ({ row }) => (
        <p className={styles.deletedPlan}>
          Borrado el {moment.tz(row.original?.deleted_date, "America/Bogota").format("YYYY-MM-DD HH:mm")}
        </p>
      ),
      width: 20,
      sort: true,
    },
    {
      Header: "",
      accessor: "is_finished",
      id: "edit",
      Cell: ({ row }) => {
        const planCanBeRestored = user.email === row.original?.deleted_by;
        return planCanBeRestored ? (
          <div
            className={styles.restoreContainer}
            onClick={() => {
              setPlanId(row.original.id_plan);
              setOpenRestorePlan(true);
            }}
          >
            <img className={styles.restoreArrow} src={arrowGreen} alt="arrowBack" />
            <p className={styles.restorePlan}>Restaurar</p>
          </div>
        ) : null;
      },
      disableFilters: true,
      width: 13,
    },
  ];

  useEffect(() => {
    setIdPatient(params.idPatient);

    return () => {
      setIdPatient(null);
    };
  }, []);

  useEffect(() => {
    if (idPatient) {
      request();
    }
  }, [idPatient]);

  const columns = useMemo(() => COLUMNS, []);
  const deletedColumns = useMemo(() => DELETED_PLANS_COLUMNS, []);

  const lastNumArray = patientsInfo?.events?.map((number) => {
    return number.event_number;
  });

  useEffect(() => {
    if (lastNumArray) {
      setEqualNumber(lastNumArray[0]);
    }
  }, [lastNumArray]);

  useEffect(() => {
    setTimeout(() => {
      const changeRoute = async () => {
        await modulesMixer(modules, "/desenlaces/pacientes");
      };
      changeRoute();
    }, 100);
  }, []);


  useEffect(() => {
    return () => {
      setPatientsInfo(null);
      setDiligenceInfo(null);
    }
  }, []);

  const resetRestorePlan = () => {
    setPlanId("");
    setOpenRestorePlan(false);
  };

  return (
    <div className={styles.container}>
      <PatientDataTable styleType="patientsDetail" updateInfo={request} />
      {equalNumber ? (
        <section className={styles.tablesContainer}>
          {patientsInfo?.events?.map((trackInfo, id) => {
            const listOpt = trackInfo.plans_list
            const newOpt = listOpt.map(opt => {
              return {
                ...opt,
              }
            })

            return (
              <div key={id}>
                <TableCardFSFB
                  location="desenlaces"
                  deletedColumns={deletedColumns}
                  deletedData={trackInfo.deleted_plans_list}
                  columns={columns}
                  accordionData={patientsInfo.events[id]}
                  data={newOpt}
                  trackInfo={trackInfo}
                  diligenceInfo={patientsInfo}
                  onClick={handleStop}
                  undelete={handleUndelete}
                  equalNumber={equalNumber}
                />
              </div>
            )
          })}
        </section>
      ) : null}
      <Modal
        isOpen={openRestorePlan}
        onClose={() => resetRestorePlan()}
        variant="large"
      >
        <RestorePlanStepper
          planId={planId}
          updateScreen={request}
          onClose={() => resetRestorePlan()}
        />
      </Modal>

      <Modal
        isOpen={openDeleteTracing || openUndeleteTracing}
        onClose={
          openDeleteTracing
            ? () => setOpenDeleteTracing(false)
            : () => handleUndeleteClose()
        }
        variant={openDeleteTracing ? "large" : "confirm"}
      >
        {openDeleteTracing ? (
          <DeleteEvents
            onClose={() => setOpenDeleteTracing(false)}
            updateInfo={request}
            valuesDelete={1}
            selectOptions={idEvent}
            user={user}
          />
        ) : loading ? (
          <Spinner className_container="rolesForm" />
        ) : (
          <ModalContent
            btns={undeleteStatus ? false : true}
            close={() => handleUndeleteClose()}
            confirm={undelete}
            text={
              undeleteStatus === 200
                ? `Evento restaurado`
                : `Estás a punto de\nrestaurar un evento`
            }
            helpText={
              undeleteStatus === 200
                ? `Podrás visualizarlo\nen la tabla de eventos`
                : `¿Deseas continuar?`
            }
            icon={undeleteStatus ? userUpdate : delete_rol}
            alt="Icono activar evento"
          />
        )}
      </Modal>

      <Modal
        isOpen={openEditTracing}
        onClose={() => handleCloseModal()}
        variant="large"
      >
        <EditEvents
          onClose={() => handleCloseModal()}
          id_plan={idScaleSelected}
        />
      </Modal>
    </div>
  );
}


export default withAuth(PatientDesenlacesDetail, '/desenlaces/pacientes')